import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: 'https://backoffice.gourdefriendly.green',
  baseURL: 'https://backend.csibon.app',

    // baseURL: 'http://127.0.0.1:5005',
});

axiosInstance.interceptors.request.use(async config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const refreshToken = localStorage.getItem('refresh_token');
    try {
      const response = await axios.post('/refresh_token', { refresh_token: refreshToken });
      localStorage.setItem('token', response.data.access_token);
      return axiosInstance(originalRequest);
    } catch (refreshError) {
      localStorage.clear();
      window.location.href = '/';
      return Promise.reject(refreshError);
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;
